/* eslint-disable no-undef, react/prop-types, react/no-danger */
import React, { Component } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { graphql, Link, navigate } from 'gatsby';
import Markdown from 'react-markdown';
import { Helmet } from 'react-helmet';
import { SRLWrapper } from 'simple-react-lightbox';
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image';
import PageTransition from '../components/PageTransition/index';
import { exBarNav, exBarItem, exBarContent, exBarLink, exBarBar, navBasic } from '../style/mouseOverUnderline';
import { breadCrumb, breadcrumbFix, inline } from '../style/breadCrumb';

import { Box, Flex } from '../style/basicStyle';
import colors from '../utils/colors';

const ImageContainer = styled.div`
  height: 226px;
  width: 226px;
`;

const Bio = styled.div`
  & a {
    text-decoration: underline;
  }
  & a:hover {
    color: darkblue;
  }
`;

const removeMarginMobile = css`
  @media (max-width: 768px) {
    margin-top: -40px;
  }
  margin-bottom: 40px;
`;

const imageCss = css`
  width: 100%;
  height: auto;
  border-radius: 50%;
`;

// use markdown converter, place content in YAML files for team members.

function LinkRenderer({ href, children }) {
  return (
    <a href={href} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  );
}

class TeamTemplate extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { props } = this;
    props.toggleNavDark({ navBarDark: true });
  }

  render() {
    const { props } = this;
    const { data } = props;
    const member = data.allTeamYaml && data.allTeamYaml.edges && data.allTeamYaml.edges[0].node;
    const imageArray = data && data.teamMemberImages && data.teamMemberImages.edges;
    const profileImage = imageArray && imageArray[0] && imageArray[0].node;
    const childImageSharp = profileImage && profileImage.childImageSharp;
    const gatsbyImageData = childImageSharp && childImageSharp.gatsbyImageData;
    const teamMemberName = member.teamMemberNameFull || member.teamMemberName;
    const jerseyNumber = member.jerseyNumber || '';
    const { isNonPlayer } = member;
    const position = (isNonPlayer && member.title) || (member.title && member.title !== 'null' && `Position: ${member.title}`) || '';
    const { fields } = member;
    let { teamType } = fields;
    teamType = teamType && teamType[0] && teamType[0].length && teamType[0];
    return (
      <PageTransition location={props.location}>
        <Helmet htmlAttributes={{ lang: 'en' }}>
          <title>
            Florida Bulldogs Team Member -{teamMemberName} -{position}
          </title>
        </Helmet>
        <Flex css={breadCrumb} wrap={['nowrap']}>
          <Box m={[0]} width={[1, 1, 1]} p={[1, 2]}>
            <div css={[exBarItem, breadcrumbFix]}>
              <div css={[exBarContent, inline]}>Team </div>
              <Link to="/team" css={exBarLink}>
                <span css={exBarBar} />
              </Link>
            </div>
            &gt;
            <div css={[exBarItem, breadcrumbFix]}>
              <div css={[exBarContent, inline]}>{teamType} </div>
              <Link to={`/team/${teamType.toLowerCase()}`} css={exBarLink}>
                <span css={exBarBar} />
              </Link>
            </div>
            &gt; {teamMemberName}
          </Box>
        </Flex>
        <Flex css={removeMarginMobile}>
          <Box p={[3, 3, 0]} width={[1, 8 / 10, 8 / 10, 7 / 10]} m={['1rem auto 0 auto', '1rem auto 0 auto', '1rem auto 0 auto', '1rem auto 0 auto']} color={colors.secondary}>
            <Flex wrap={['wrap']}>
              <ImageContainer>{gatsbyImageData && gatsbyImageData.images ? <SRLWrapper><GatsbyImage image={gatsbyImageData} alt="profile image"/></SRLWrapper> : <StaticImage alt="profile image placeholder" css={imageCss} src="../../bulldogshockeyassets/images/teamPlaceholder.jpg" />}</ImageContainer>
              <Box fontSize={[4, 4, 5, 6]} m={[1]}>
                {teamMemberName}
                <Box fontSize={[2, 2, 3, 4]} m={[0]}>
                  {position}
                </Box>
                {!isNonPlayer && jerseyNumber && (
                  <Box fontSize={[2, 2, 3, 4]} m={[0]}>
                    {`#${jerseyNumber}`}
                  </Box>
                )}
              </Box>
            </Flex>
            {member.bio && member.bio.length > 10 && (
              <Box fontSize={[2, 2, 3]} m={[1]}>
                <Bio>
                  {/* eslint-disable-next-line react/no-children-prop */}
                  <Markdown children={member.bio} components={{ link: LinkRenderer }} />
                </Bio>
              </Box>
            )}
          </Box>
        </Flex>
      </PageTransition>
    );
  }
}

export const pageQuery = graphql`
  query TeamByPath($slug: String!, $relativeDirectory: String!) {
    teamMemberImages: allFile(
      filter: { sourceInstanceName: { eq: "teamMembers" }, relativeDirectory: { eq: $relativeDirectory }, internal: { mediaType: { regex: "/image/" } } }
      sort: { fields: [relativePath], order: ASC }
    ) {
      edges {
        node {
          id
          name
          relativePath
          sourceInstanceName
          childImageSharp {
            gatsbyImageData(layout: FIXED, width: 226, height: 226, quality: 90)
          }
        }
      }
    }
    allTeamYaml(filter: { fields: { slug: { eq: $slug } } }) {
      edges {
        node {
          teamMemberName
          teamMemberNameFull
          jerseyNumber
          isNonPlayer
          title
          bio
          date(formatString: "MMMM DD, YYYY")
          fields {
            teamType
            slug
            relativeDirectory
          }
        }
      }
    }
  }
`;
export default TeamTemplate;
